import React, { useEffect } from 'react';
import './privacy.css';
import { addTitle } from '../../../utils/DynamicTitle';

const PrivacyPolicy = () => {
    useEffect(() => {
        addTitle("Privacy Policy");
    },[])
    return (
        <div className='privacy-container'>
            <div className='news news_page'>
            <h1>Privacy Policy</h1>
            <h2>Collection of Information</h2>

            <span className='common-heading'>Information You Provide to Us</span>
            <p className='para'>We collect information you provide directly to us, such as when you, request on-demand services, contact customer
                support, or otherwise communicate with us. This information may include: name, email, phone number, postal
                address, profile picture, and other information you choose to provide.
            </p>

            <span className='common-heading'>Information We Collect Through Your Use</span>
            <p className='para'>
                When you use our Services, we collect information about you in the following general categories:
                <ul>
                    <li>
                        <span className='span-heading'>Location Information:{" "}</span>
                        When you use the Services, we collect precise location data (GPS and network-
                        based). If you permit the Blife app/website to access location services through the permission system used
                        by your mobile operating system ("platform"), we may also collect the precise location of your device when
                        the app is running in the foreground or background to send retail store's promotion. We may also derive
                        your approximate location from your IP address in order to make the app/website faster.
                    </li>
                    <li>
                        <span className='span-heading'>Contacts Information:{" "}</span>
                        If you permit the Blife app/website to access the address book on your device
                            through the permission system used by your mobile platform or website, we may access and store names
                            and contact information from your address book on the device to facilitate social interactions through our
                            Services. We may use the said information to check if the user is connected with network or not and to let
                            user call in case if user taps on contact number.
                    </li>
                    <li>
                        <span className='span-heading'>Usage and Preference Information:{" "}</span>
                        We collect information about how you and site visitors interact with
                        our Services, preferences expressed, and settings chosen. In some cases we do this through the use of
                        cookies and similar technologies that create and maintain unique identifiers.
                    </li>
                    <li>
                        <span className='span-heading'>Device Information:{" "}</span>
                        We may collect information about your mobile device, including, for example, the
                        hardware model, operating system and version, software and file names and versions, preferred language,
                        unique device identifier, advertising identifiers, serial number, device motion information, and mobile
                        network information to retrieve running apps and to improve performance. We may access information to
                        find accounts on the device such as directly call phone numbers, read phone status and identity.
                    </li>
                    <li>
                        <span className='span-heading'>Call and SMS Data:{" "}</span>
                        Our app/website facilitates communication between Users and Customer Support. In
                        connection with this service, we receive call data, including the date and time of the call or SMS message,
                        the parties' phone numbers, and the content of the SMS message and sending OTP(s).
                    </li>
                    <li>
                        <span className='span-heading'>Log Information:{" "}</span>
                        When you interact with the Services, we collect server logs, which may include
                        information like device IP address, access dates and times, app features or pages viewed, app crashes and
                        other system activity, type of browser, and the third-party site or service you were using before interacting
                        with our Services. This information may be used to check network connection, receiving data from internet,
                        prevent device from sleeping, enable reading phone status and viewing Wi-Fi connection.
                    </li>
                </ul>
            </p>
            <h2>Use of Information</h2>
            <p className='para'>
                We may use the information we collect about you to
                <ul>
                    <li>
                        Perform internal operations, including, for example, to prevent fraud and abuse of our Services; to
                        troubleshoot software bugs and operational problems; to conduct data analysis, testing, and research; and
                        to monitor and analyze usage and activity trends;
                    </li>
                    <li>
                        Send you communications we think will be of interest to you, including information about products,
                        services, promotions, news, and events of Blife and to process contest, sweepstake, or other promotion
                        entries and fulfill any related awards;
                    </li>
                    <li>
                        Personalize and improve the Services, including providing or recommend features, content, social
                        connections, referrals, and advertisements.
                    </li>
                </ul>
            </p>
            <h2>Sharing of Information</h2>
            <p className='para'>
                We may share the information we collect about you as described in this Statement or as described at the time of
                collection or sharing, including as follows:
                <div className='info-title'>
                    Through Our Services
                </div>
                We may share your information:
                <ul>
                    <li>
                        With third parties to provide you a service you requested through a partnership or promotional offering
                        made by a third party or us;
                    </li>
                    <li>
                        With the general public if you submit content in a public forum, such as blog comments, social media posts,
                        or other features of our Services that are viewable by the general public;
                    </li>
                    <li>
                        With third parties with whom you choose to let us share information, for example other apps or websites
                        that integrate with our Services; and
                    </li>
                </ul>
                <div className='info-title'>
                    Other Important Sharing
                </div>
                We may share your information:
                <ul>
                    <li>
                        With Blife subsidiaries and affiliated entities that provide services or conduct data processing on our behalf,
                        or for data centralization and / or logistics purposes;
                    </li>
                    <li>
                        With vendors, consultants, marketing partners, and other service providers who need access to such
                        information to carry out work on our behalf;
                    </li>
                    <li>
                        In response to a request for information by a competent authority if we believe disclosure is in accordance
                        with, or is otherwise required by, any applicable law, regulation, or legal process;
                    </li>
                    <li>
                        With law enforcement officials, government authorities, or other third parties if we believe your actions are
                        inconsistent with ourr policies, or to protect the rights, property, or safety of Blife or others;
                    </li>
                    <li>
                        In connection with, or during negotiations of, any merger, sale of company assets, consolidation or
                        restructuring, financing, or acquisition of all or a portion of our business by or into another company;
                    </li>
                    <li>If we otherwise notify you and you consent to the sharing; and</li>
                    <li>In an aggregated and/or anonymized form which cannot reasonably be used to identify you.</li>
                </ul>
            </p>
            <h2>Social Sharing Features</h2>
            <p className='para'>
                The Services may integrate with social sharing features and other related tools which let you share actions you take
                on our Services with other apps, sites, or media, and vice versa. Your use of such features enables the sharing of
                information with your friends or the public, depending on the settings you establish with the social sharing service.
                Please refer to the privacy policies for more information about how they handle the data you provide to or share
                through them.
            </p>
            <p className='para'>
                Our promotional offers/discounts are not sitewide and are limited to selected categories. Coupon codes may not be
                applicable on categories like diapers, baby food etc. or such other product or service as may be determined by us in
                our sole discretion.
            </p>
            <h2>Analytics and Advertising Services Provided by Others</h2>
            <p className='para'>
                We may allow others to provide audience measurement and analytics services for us, to serve advertisements on our
                behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use
                cookies and other technologies to identify your device when you visit our app/website and use our Services, as well
                as when you visit other online sites and services.
            </p>
            <h2>Your Choices</h2>
            <p className='para'>
                <div className='info-title'>
                    Access Rights
                </div>
                Blife will comply with individual's requests regarding access, correction, and/or deletion of the personal data it stores
                in accordance with applicable law.
            </p>
            <p className='para'>
                <div className='info-title'>
                    Location Information
                </div>
                We request permission for our app's collection of precise location from your device per the permission system used
                by your mobile operating system.
            </p>
            <p className='para'>
                <div className='info-title'>
                    Contact Information
                </div>
                We may also seek permission for our app's collection and syncing of contact information from your device per the
                permission system used by your mobile operating system.
            </p>
            <p className='para'>
                <div className='info-title'>
                    Promotional Communications
                </div>
                You may opt out of receiving promotional messages from us by following the instructions in those messages. If you
                opt out, we may still send you non-promotional communications, such as those , about Services you have requested,
                or our ongoing business relations.
            </p>
            <p className='para'>
                If you initially permit the collection of any information, you can later disable it by changing the settings on your
                mobile device. However, this will limit your ability to use certain features of our Services.
            </p>
            <h2>Promotional Communication Through WhatsApp Messenger</h2>
            <p className='para'>By opting in/accepting the terms and conditions, you (the “User”) give consent to Blife to communicate with you on
                WhatsApp for all its transactional and promotional messages/communication needs. We shall store your details
                responsibly and use them to enrich your experience with us & provide the best deals & discounts.</p>
            <h2>Changes to the Statement</h2>
            <p className='para'>
                We may change this Statement from time to time. If we make significant changes in the way we treat your personal
                information, or to the Statement, we will provide you notice through the Services or by some other means, such as
                email. Your continued use of the Services after such notice constitutes your consent to the changes. We encourage
                you to periodically review the Statement for the latest information on our privacy practices.
            </p>
            </div>
            
        </div>
    )
}

export default PrivacyPolicy